export default [
    {
        title: 'Wer kann an der NEW-Vereinsförderung teilnehmen?',
        content: 'Alle gemeinnützig anerkannten Vereine oder Organisationen in kommunaler oder kirchlicher Trägerschaft aus der Förderregion der NEW AG können teilnehmen. Die Förderregion wird im Rahmen des Wettbewerbs in drei Regionen eingeteilt: Mönchengladbach (gemeinsam mit den Städten Grevenbroich, Jüchen und Korschenbroich), Viersen und Heinsberg.'
    },
    {
        title: 'Wann beginnt die Bewerbungsphase der NEW-Vereinsförderung?',
        content: 'Die Bewerbungsphase startete am 07.03.2025. Die Qualifikationsphase wird am 20.05.2025 enden.'
    },
    {
        title: 'Wie geht es nach der Bewerbungsphase weiter?',
        content: 'Ab dem 29. April 2025 um 10 Uhr bis zum 20. Mai 2025 um 12 Uhr läuft die Qualifikationsphase. Hier kann täglich per Klick für das Lieblingsprojekt abgestimmt werden. Die 60 bestplatzierten Vereine je Region, die am 20. Mai 2025 um 12 Uhr die meisten Stimmen aufweisen, ziehen dann ins Finale ein. Im Finale starten alle Projekte erneut mit null Stimmen. Vom 20. Mai 2025 ab 12 Uhr bis zum 22. Mai 2025 um 11:59 Uhr entscheidet sich dann, mit welcher finalen Summe die 60 Vereine je Region gefördert werden.'
    },
    {
        title: 'Wie kann für die jeweiligen Projekte abgestimmt werden?',
        content: 'Nach Eingabe der Mobilfunknummer erhält man per SMS einen Abstimmcode, dieser muss zur Bestätigung bei der Abstimmung eingeben werden. In der Qualifikationsphase kann täglich für ein Projekt abgestimmt werden. Im Finale kann dann nur noch einmalig via Abstimmcode abgestimmt werden.'
    },
    {
        title: 'Welche Preise gibt es bei der NEW-Vereinsförderung im Jahr 2025?',
        content: 'Bei der NEW-Vereinsförderung können dieses Jahr drei Preise gewonnen werden. Den Publikumspreis, den Jurypreis und zum ersten Mal wird es zusätzlich den Kommunenpreis geben. '
    },
    {
        title: 'Was ist der Publikums- und Jurypreis?',
        content: 'Bei dem Publikumspreis werden je Region 60 Vereine gefördert mit insgesamt 35.000 Euro. Die 180 Finalist:innen und damit Publikumspreisträger:innen erhalten Publikumspreise zwischen 300 und 2.000 Euro. Zusätzlich vergibt eine Jury einen Jurypreis an 10 weitere Vereine. Dieser hat jeweils einen Wert von 1.000 Euro.'
    },
    {
        title: 'Was ist der Kommunenpreis? ',
        content: 'Gemeinsam mit Ihnen, den Kommunen aus unserem Versorgungsgebiet, möchten wir menschliche Begegnung fördern und die „helfenden Hände“ in Ihrer Kommune auszeichnen. Dazu zählen Vereine und Organisationen, die sich in besonderer Weise mit dem Projekt für menschliche Begegnungen einsetzen. Für den Kommunenpreis stellt die NEW insgesamt 15.000€ zur Verfügung.'
    },
    {
        title: 'An wen wird der Kommunenpreis verliehen?',
        content: 'In diesem Jahr wird es zum ersten Mal einen Kommunenpreis geben. Dafür wird je Region (Mönchengladbach, Viersen, Heinsberg) die Kommune aus unserem Versorgungsgebiet ermittelt, aus der die meisten teilnehmenden Vereine im Verhältnis zur Einwohnerzahl stammen. Diese drei Kommunen werden dann zur Auswahl der Vereine und Organisationen berechtigt, die das Kommunenpreisgeld in Höhe von jeweils 5.000 Euro erhalten. '
    },
    {
        title: 'Wer bestimmt die Vereine/Organisation, an die die Förderung aus dem Kommunenpreis gehen soll?',
        content: 'Wenn aus Ihrer Kommune die meisten Vereine im Verhältnis zur Einwohnerzahl je Region stammen, können Sie uns ein oder maximal zwei lokale Vereine oder Organisationen für die Vergabe des Kommunenpreisgelds benennen. Hierbei spielt es keine Rolle, ob die Vereine oder Organisationen an der NEW-Vereinsförderung teilgenommen haben.'
    },
    {
        title: 'Wie kann ich die NEW-Vereinsförderung innerhalb meiner Kommune bewerben?',
        content: 'Sie finden vorgefertigte Grafiken und Texte zur Bewerbung der NEW-Vereinsförderung unter <a href="https://www.new-vereinsfoerderung.de/infos-kommunen">https://www.new-vereinsfoerderung.de/infos-kommunen</a>. Diese können Sie gerne für die Bewerbung der NEW-Vereinsförderung nutzen. '
    },
    {
      title: 'Wann wird der Kommunenpreis verliehen?',
      content: 'Der Kommunenpreis wird auf der großen Vereinsförderungsfeier am 10.September 2025, 18 Uhr, bei der NEW in Mönchengladbach verliehen.'
    }
]
